import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import BlogPostListing from '../components/BlogPostListing'
import PhotoPostListing from '../components/PhotoPostListing'
import MusingPostListing from '../components/MusingPostListing'
import SEO from '../components/seo'

const getListing = node => {
  switch (node.fields.collection) {
    case 'photos':
      return <PhotoPostListing node={node} key={node.id} />
    case 'blog':
    case 'writing':
      return node.frontmatter.title ? (
        <BlogPostListing node={node} key={node.id} />
      ) : (
        <MusingPostListing node={node} key={node.id} />
      )
  }
}
const PostIndex = ({ data }) => {
  const posts = data.allMdx.nodes

  return (
    <Layout>
      <SEO title="Home" />
      <h1>Home</h1>
      {posts.map(getListing)}
    </Layout>
  )
}

export default PostIndex

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { ne: true }, date: { ne: null } } }
    ) {
      nodes {
        id
        body
        excerpt
        fields {
          tagSlugs
          collection
          slug
        }
        frontmatter {
          title
          date(formatString: "MMMM Do, YYYY")
          dateTime: date(formatString: "MMMM Do, YYYY, h:mm A")
          dataDate: date
          tags
          photos {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
